import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export const Seo = ({ urlLoc }) => {
  const data = useStaticQuery(graphql`
    query Seo {
      allSanitySeo {
        nodes {
          id
          url
          title
          siteName
          keywords
          description
          image {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  if (data && data.allSanitySeo && data.allSanitySeo.nodes.length !== 0) {
    const {
      url,
      title,
      description,
      siteName,
      keywords,
      image,
    } = data.allSanitySeo.nodes.find(seoNode => seoNode.url === urlLoc)
    return (
      <Helmet>
        <html lang="nl-BE" />
        {title && <title>{title}</title>}

        <meta name="description" content={description} />
        <meta name="image" content={image.asset.url} />
        <meta name="keywords" content={keywords.join(", ")} />

        <meta property="og:type" content="website" />
        {title && <meta property="og:title" content={title} />}
        {url && <meta property="og:url" content={url} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {siteName && <meta property="og:site_name" content={siteName} />}
        {image && <meta property="og:image" content={image.asset.url} />}

        <meta name="twitter:card" content="summary_large_image" />
        {title && <meta name="twitter:title" content={title} />}
        {description && (
          <meta name="twitter:description" content={description} />
        )}
        {/* {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )} */}
        {image && <meta name="twitter:image" content={image.asset.url} />}
      </Helmet>
    )
  } else {
    return ""
  }
}
